<template>
  <Prime-Page>
    <ion-content>
      <ion-grid class="margin-bottom-twenty full-width full-height prime-patient-view">
        <ion-row v-if="isLoading" class="full-height">
          <div class="centerItems text-muted display-flex valign full-height full-width">
            <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
          </div>
        </ion-row>
        <ion-row v-else class="ion-justify-content-center full-height">
          <ion-col size-sm="10" size-md="8" size-lg="7" class="full-height">
            <h3>{{ pageCopy.header }}</h3>

            <!-- CONVERSATIONS BLOCK -->
            <div class="prime-form-block-container">
              <!-- Normal Priority Messages -->
              <h6>Conversations</h6>

              <ion-card v-for="(conversation, index) in conversationsObj" :key="index" class="prime-professional-default-card ion-color ion-color-white">
                <ion-card-header class="cursor-pointer ion-align-items-center">
                  <ion-card-title>
                    <div class="prime-form-block-title-row keep-input-inline ion-align-items-center">
                      <div class="prime-form-block-title prime-form-block-title-row ion-align-items-center">
                        <IconFlag v-if="conversation.context === 'urgent'" name="md-repeat" v-tooltip.top="'Message is Urgent'" class="is-urgent ion-margin-end" />
                        <h6>{{ matchKey(conversation.context.toUpperCase()).label }}</h6>
                        <IconInfoCircle v-tooltip="matchKey(conversation.context.toUpperCase()).toolTip" class="form-tooltip-icon margin-left-five" />
                      </div>
                      <div class="prime-form-block-input">
                        <span v-if="conversation.active">Receive</span>
                        <span v-else>Don't Receive</span>
                        <ion-toggle color="primary" slot="end" @ionChange="conversation.active = !conversation.active" :checked="conversation.active" mode="ios"></ion-toggle>
                      </div>
                    </div>
                  </ion-card-title>
                </ion-card-header>

                <ion-card-content :class="{ active: conversation.active }" class="md hydrated">
                  <div v-if="conversation.active" class="prime-form-block-subcontainer margin-top-five margin-bottom-five label">
                    <span class="label">Receive first notification by</span>
                    <v-select multiple :options="channelSelectOptions" v-model="conversation.channel" :value="conversationSelected" class="prime-vue-select"></v-select>
                    <span class="label">after</span>
                    <v-select :clearable="false" :options="timeLimit" v-model="conversation.timing.delay" class="prime-vue-select"></v-select>
                    <span class="label">minutes</span>
                  </div>

                  <div v-if="conversation.active" class="prime-form-block-subcontainer margin-top-ten margin-bottom-ten">
                    <div class="prime-form-block-description">
                      If a patient's message remains unanswered
                    </div>
                  </div>

                  <div v-if="conversation.active" class="prime-form-block-subcontainer margin-top-five margin-bottom-five label">
                    <span>Remind me every</span>
                    <v-select :clearable="false" :options="timeLimit" v-model="conversation.timing.repeat" class="prime-vue-select"></v-select>
                    <span>minutes, up to</span>
                    <v-select :clearable="false" :options="numberOfTimes" v-model="conversation.timing.maxAttempts" class="prime-vue-select"></v-select>
                    <span>times.</span>
                  </div>
                </ion-card-content>
              </ion-card>
            </div>
            <!-- END CONVERSATIONS BLOCK -->

            <!-- TASKS BLOCK -->
            <div v-if="$can(I.VIEW_ADVANCED_NOTIFICATIONS)" class="prime-form-block-container">
              <h6>Tasks</h6>
              <ion-card v-for="(task, index) in tasksObj" :key="index" class="prime-professional-default-card ion-color ion-color-white">
                <ion-card-header class="cursor-pointer ion-align-items-center">
                  <ion-card-title>
                    <div class="prime-form-block-title-row keep-input-inline ion-align-items-center">
                      <div class="prime-form-block-title prime-form-block-title-row ion-align-items-center">
                        <h6>{{ matchKey(task.context.toUpperCase()).label }}</h6>
                        <IconInfoCircle v-tooltip="matchKey(task.context.toUpperCase()).toolTip" class="form-tooltip-icon margin-left-five" />
                      </div>
                      <div class="prime-form-block-input">
                        <span v-if="task.active">Receive</span>
                        <span v-else>Don't Receive</span>
                        <ion-toggle color="primary" slot="end" @ionChange="task.active = !task.active" :checked="task.active" mode="ios"></ion-toggle>
                      </div>
                    </div>
                  </ion-card-title>
                </ion-card-header>

                <ion-card-content :class="{ active: task.active }" class="md hydrated">
                  <div v-if="task.active" class="prime-form-block-subcontainer margin-top-five margin-bottom-five label">
                    <span class="label">Receive by</span>
                    <v-select multiple :options="channelSelectOptions" v-model="task.channel" class="prime-vue-select"></v-select>
                  </div>
                </ion-card-content>
              </ion-card>
            </div>
            <!-- END TASKS BLOCK -->

            <!-- EVENTS BLOCK -->
            <div v-if="$can(I.VIEW_ADVANCED_NOTIFICATIONS)" class="prime-form-block-container">
              <h6>Events</h6>

              <ion-card v-for="(event, index) in eventsObj" :key="index" class="prime-professional-default-card ion-color ion-color-white">
                <ion-card-header class="cursor-pointer ion-align-items-center">
                  <ion-card-title>
                    <div class="prime-form-block-title-row keep-input-inline ion-align-items-center">
                      <div class="prime-form-block-title prime-form-block-title-row ion-align-items-center">
                        <h6>{{ matchKey(event.context.toUpperCase()).label }}</h6>
                        <IconInfoCircle v-tooltip="matchKey(event.context.toUpperCase()).toolTip" class="form-tooltip-icon margin-left-five" />
                      </div>
                      <div class="prime-form-block-input">
                        <span v-if="event.active">Receive</span>
                        <span v-else>Don't Receive</span>
                        <ion-toggle color="primary" slot="end" @ionChange="event.active = !event.active" :checked="event.active" mode="ios"></ion-toggle>
                      </div>
                    </div>
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content :class="{ active: event.active }" class="display-flex flex-column md hydrated event-multi-block-container">
                  <div v-if="event.active" class="display-flex flex-space-between prime-form-block-subcontainer margin-top-five event-sub-block margin-bottom-five">
                    <span class="label-color">Receive by</span>
                    <v-select multiple :options="channelSelectOptions" v-model="event.channel" class="prime-vue-select"></v-select>
                  </div>
                  <div v-if="event.active && showPresetLimit" class="display-flex flex-space-between prime-form-block-subcontainer margin-top-five margin-bottom-five event-sub-block divider">
                    <span class="label-color">Limit to</span>
                    <div class="display-flex flex-column">
                      <span class="prime-form-block-description">Saved Roster Preset</span>
                      <v-select :options="getPresets" v-model="event.presetName" class="prime-vue-select"></v-select>
                    </div>
                  </div>
                </ion-card-content>
              </ion-card>
            </div>
            <!-- END EVENTS BLOCK -->
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </Prime-Page>
</template>
<script>
import vSelect from "vue-select";
import { EventBus } from "@/services/Events.js";
import IconFlag from "@/components/Global/Icons/IconFlag";
import IconInfoCircle from "@/components/Global/Icons/IconInfoCircle";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import PrimePage from "@/components/Global/PrimePage";

export default {
  name: "ProfessionalNotificationSettings",
  components: {
    vSelect,
    IconFlag,
    IconInfoCircle,
    PrimePage
  },
  data() {
    return {
      pageCopy: this.$languagePack.userSettings.notifications,
      isAllOrgs: null,
      normalNotificationsTiming: {
        timing: {
          id: null,
          professionalId: null,
          delay: 5,
          repeat: 1,
          maxAttempts: 1
        }
      },
      conversationSelected: [],
      channelSelectOptions: ["sms", "email"],
      professionalId: this.$route.params.id,
      isLoading: false,
      numberOfTimes: _.range(1, 6),
      timeLimit: [1, 5, 15, 30, 60, 120],
      clonedData: null,
      selectedPreset: ""
    };
  },

  computed: {
    ...mapGetters({
      conversationsObj: "professionalSettings/getConversations",
      tasksObj: "professionalSettings/getTasks",
      eventsObj: "professionalSettings/getEvents",
      allOrgsObj: "professionalSettings/getAllOrgs",
      showPresetLimit: "professionalSettings/getShowPresetLimit",
      orgId: "organizations/getOrganizationId",
      getClonedConversationsData: "professionalSettings/getClonedConversationsData",
      getClonedTasksData: "professionalSettings/getClonedTasksData",
      getClonedEventsData: "professionalSettings/getClonedEventsData",
      getAllClondedData: "professionalSettings/getAllClondedData",
      getPresets: "professionalSettings/getPresets"
    })
  },

  watch: {
    conversationsObj: {
      handler(newVal) {
        const selectedObject = newVal.slice();

        const fullObject = [
          ...selectedObject,
          ...this.tasksObj,
          ...this.eventsObj
        ].slice();

        const newObject = JSON.stringify(JSON.stringify(fullObject)).replace(/[\[\]']+/g, "");
        const comparingObject = JSON.stringify(this.getAllClondedData).replace(/[\[\]']+/g, "");

        this.handleIntegrityValidation(newObject, comparingObject);
      },
      deep: true
    },

    tasksObj: {
      handler(newVal) {
        const selectedObject = newVal.slice();

        const fullObject = [
          ...this.conversationsObj,
          ...selectedObject,
          ...this.eventsObj
        ].slice();

        const newObject = JSON.stringify(JSON.stringify(fullObject)).replace(/[\[\]']+/g, "");
        const comparingObject = JSON.stringify(this.getAllClondedData).replace(/[\[\]']+/g, "");

        this.handleIntegrityValidation(newObject, comparingObject);
      },
      deep: true
    },

    eventsObj: {
      handler(newVal) {
        const selectedObject = newVal.slice();
        
        const fullObject = [
          ...this.conversationsObj,
          ...this.tasksObj,
          ...selectedObject,
        ].slice();

        const newObject = JSON.stringify(JSON.stringify(fullObject)).replace(/[\[\]']+/g, '');
        const comparingObject = JSON.stringify(this.getAllClondedData).replace(/[\[\]']+/g, '');

        this.handleIntegrityValidation(newObject, comparingObject);
      },
      deep: true
    }
  },

  mounted() {
    this.isAllOrgs = this.allOrgsObj;
    this.cloneData();
  },

  methods: {
    ...mapActions({
      setClonedConversationsData: "professionalSettings/setClonedConversationsData",
      setClonedEventsData: "professionalSettings/setClonedEventsData",
      setClonedTasksData: "professionalSettings/setClonedTasksData"
    }),

    matchKey(value) {
      return Object.entries(this.pageCopy.notificationTypes).filter(x => x[0] === value)[0][1];
    },

    handleIntegrityValidation(selection, obj) {
      EventBus.$emit("onHasChanged", selection === obj);
    },

    cloneData() {
      this.setClonedConversationsData([...this.conversationsObj]);
      this.setClonedTasksData([...this.tasksObj]);
      this.setClonedEventsData([...this.eventsObj]);
    }
  }
};
</script>
<style lang="scss">
.prime-vue-select {
  .vs__selected-options {
    visibility: visible;

    .vs__selected {
      background: var(--color-secondary-button);
      border: none;
      border-radius: 20px;
      padding: 4px 12px;
      position: relative;

      button {
        margin-left: 10px;
        border-radius: 50%;
        padding: 5px;
        background: var(--ion-color-medium);

        svg {
          fill: var(--ion-color-white);
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
}

.label {
  display: inline-block;
  color: var(--ion-color-white-contrast);
}

.label-color {
  display: inline-block;
  color: var(--ion-color-white-contrast);
}

.is-urgent {
  fill: var(--ion-color-danger);
  max-width: 17px;
  min-width: 17px;
}

.prime-vue-select .vs__dropdown-toggle {
  height: 40px;
}

.prime-form-block-container {
  margin-bottom: 40px;
}

.prime-form-block-input {
  font-size: 14px;
  font-weight: 600;

  span {
    margin-right: 10px;
  }
}

.prime-channel-tabs-container ion-segment-button.segment-button-checked {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.prime-form-block-subcontainer {
  margin-left: 0;
}

.prime-professional-default-card {
  margin: 5px 0 5px 0 !important;
  padding: 10px 0 !important;
  overflow: visible;

  .divider {
    padding-top: 15px;
    margin-top: 10px;
    border-top: 1px solid var(--ion-color-light-shade);
  }

  ion-card-content {
    &.active {
      padding: 15px;
      margin-top: 10px;
      border-top: 1px solid var(--ion-color-light-shade);
    }
  }

  ion-card-content.event-multi-block-container {
    &.active {
      padding: 0;
      border-top: 1px solid var(--ion-color-light-shade);
    }
  }

  .event-sub-block {
    align-items: center;
    padding: 15px;
  }
} 
</style>
